import section1 from "../../assets/images/logo.png";
import section2 from "../../assets/images/glg.svg";
import section3 from "../../assets/images/binance_logo.svg";
import section4 from "../../assets/images/wefunder_logo1.svg";
import section5 from "../../assets/images/digital ocean.png";
import section6 from "../../assets/images/glg.svg";
import section7 from "../../assets/images/binance_logo.svg";
import section8 from "../../assets/images/digital ocean.svg";
import section9 from "../../assets/images/guide.svg";
import section10 from "../../assets/images/nasdaq_logo1.svg";
import section11 from "../../assets/images/netcapital_logo.svg";
import section12 from "../../assets/images/wefunder_logo1.svg";
import section13 from "../../assets/images/founder-select-portfolio.svg";
import section14 from "../../assets/images/asset_img1.png";
import section15 from "../../assets/images/asset_img3.png";
import section16 from "../../assets/images/asset_img2.png";
import section17 from "../../assets/images/asset_img3.png";
import section18 from "../../assets/images/woman.png";
import section19 from "../../assets/images/block_img1.png";
import section20 from "../../assets/images/block_img2.png";
import section21 from "../../assets/images/block_img3.png";
import section22 from "../../assets/images/block_img4.png";
import section23 from "../../assets/images/broker_img.png";
import section24 from "../../assets/images/news1.png";
import section25 from "../../assets/images/news2.png";
import section26 from "../../assets/images/blog1.png";
import section27 from "../../assets/images/blog2.png";
import section28 from "../../assets/images/news3.png";
import section29 from "../../assets/images/news4.png";
import section30 from "../../assets/images/Logo_footer.png";
import section31 from "../../assets/images/caret1.png";
import person1 from "../../assets/images/person1.jpeg";
import person2 from "../../assets/images/person2.jpeg";
import person3 from "../../assets/images/person3.jpeg";
import person4 from "../../assets/images/person4.jpeg";
import person5 from "../../assets/images/person5.jpeg";
import person6 from "../../assets/images/person6.jpeg";
import person7 from "../../assets/images/person7.jpeg";
import person8 from "../../assets/images/person8.jpeg";
import person9 from "../../assets/images/person9.jpeg";
import images1 from "../../assets/images/about_img1.png";
import images2 from "../../assets/images/about_img2.png";
import images3 from "../../assets/images/sean_adler.jpg";
import images4 from "../../assets/images/anthony_mazy.png";
import images5 from "../../assets/images/harlan_milkove.png";
import images6 from "../../assets/images/sean_adler.png";
import images7 from "../../assets/images/anthony_mazy.png";
import images8 from "../../assets/images/harlan_milkove.png";
import images9 from "../../assets/images/name_icon.png";
import images10 from "../../assets/images/form_email_icon.png";
import images11 from "../../assets/images/form_email_icon.png";
import images12 from "../../assets/images/about_img3.png";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import logo from "../../assets/images/156x114.png";
import Slider from "react-slick";
import React, { useEffect, useState, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import InfoIcon from "@material-ui/icons/Info";
import { useHistory } from "react-router-dom";
import { Dropdown, Modal, Button } from "react-bootstrap";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
// import logo from "../../assets/images/145x114_inches.svg";

const Section = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const testimonials = [
    {
      content: `GZI's proprietary strategies enabled me to achieve a
        return of 56.05% on my portfolio from October 2019 to
        March 2021. I'll definitely be conserving his methods
        for future decisions.`,
      name: `Shawn C.`,
      designation: `Real Estate Entrapreneur`,
      personImageSrc: person1,
    },
    {
      content: `I met Sean at dinner with some friends back in November
        2019 where he introduced us to his company and its
        trading methodology. When I asked for a name to invest
        in, he mentioned Moderna (MRNA) as a solid pharma
        company. At the time, it was trading at $18. My husband
        and I talk about Sean every time we see the stock
        achieve new highs.`,
      name: `Wonkyung Lee`,
      designation: `Head of Macro Research, FJ Capital Management`,
      personImageSrc: person2,
    },
    {
      content: `Looking forward to checking out your next interviews.`,
      name: `Marion Jumeau`,
      designation: `Digital Marketing Entrapreneur`,
      personImageSrc: person3,
    },
    {
      content: `This is such an incredibly great interview!`,
      name: `Shona McEnroe`,
      designation: `Managing Director at Summit Coaching`,
      personImageSrc: person4,
    },
    {
      content: `This is fresh and bold mate!`,
      name: `Mark Vinch`,
      designation: `Account Executive, Everything Benefits`,
      personImageSrc: person5,
    },
    {
      content: `Thank you for featuring GZI, they’re awesome!`,
      name: `Chris Bolger, PhDC`,
      designation: `Professional Speaker, Entrapreneur`,
      personImageSrc: person6,
    },
    {
      content: `Not surprised! Well deserved feature GZI!`,
      name: `Cherf Eddine Zareb`,
      designation: `Call Center Supervisor, SDA Sonelgaz`,
      personImageSrc: person7,
    },
    {
      content: `Thanks for sharing this amazing interview.`,
      name: `Jo Joshi`,
      designation: `Founder of DMC Finder, Entrapreneur`,
      personImageSrc: person8,
    },
    {
      content: `Your story is awesome!!`,
      name: `Stefan Repin`,
      designation: `Fractional CMO, Luck Boosters`,
      personImageSrc: person9,
    },
  ];
  return (
    <>
      <head>
        <meta httpEquiv='Content-Type' content='text/html; charset=UTF-8' />
        <meta httpEquiv='x-ua-compatible' content='ie=edge' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0'
        />

        {/* stylesheet*/}
        <link rel='stylesheet' type='text/css' href='css/style.css' />
        {/* //stylesheet*/}

        <meta data-react-helmet='true' name='docsearch:version' content='2.0' />
        <meta
          data-react-helmet='true'
          name='viewport'
          content='width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover,user-scalable=0'
        />

        {/* Google fonts*/}
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'
          rel='stylesheet'
        />
        {/* //Google fonts*/}

        {/* Font Awesome*/}
        {/* <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css'
          integrity='sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=='
          crossOrigin='anonymous'
          referrerpolicy='no-referrer'
        />
        <link
          rel='stylesheet'
          href='https://pro.fontawesome.com/releases/v5.10.0/css/all.css'
          integrity='sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p'
          crossOrigin='anonymous'
        /> */}

        {/* //Font Awesome*/}

        {/* Bootstrap*/}
        <link
          rel='stylesheet'
          href='https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css'
        />
        {/* //Bootstrap*/}

        {/* owl carousel*/}
        {/* <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.css'>*/}
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.carousel.min.css'
        />
        {/* //owl carousel*/}

        {/* owl carousel in mobile*/}
        <link
          rel='stylesheet'
          href='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/assets/owl.theme.default.min.css'
        />
        {/* //owl carousel in mobile*/}

        <>
          <script src='https://code.jquery.com/jquery-2.2.4.min.js'></script>

          <script src='https://cdnjs.cloudflare.com/ajax/libs/waypoints/2.0.3/waypoints.min.js'></script>
          <script src='https://bfintal.github.io/Counter-Up/jquery.counterup.min.js'></script>
          <script type='text/javascript' src='js/counter.js'></script>

          <script src='https://cdn.jsdelivr.net/npm/jquery@3.6.0/dist/jquery.slim.min.js'></script>
          <script src='https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js'></script>
          <script src='https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js'></script>

          {/* custom js*/}
          <script type='text/javascript' src='js/tabs_js.js'></script>
          <script type='text/javascript' src='js/sticky_header.js'></script>
          {/* <script src='https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.js'></script>*/}
          <script src='https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js'></script>
          <script
            type='text/javascript'
            src='js/testimonial_carousel.js'
          ></script>
        </>
      </head>

      <section className='main_page_section home_bg all_main_sec_padding'>
        <section className='broker_section'>
          <div className='container box-container'>
            <div className='broker_row'>
              <div className='row'>
                <div className='col-md-8 col-sm-12 col-xs-12'>
                  <div className='broker_left_text'>
                    <h2>
                    GZI has received widespread acclaim on Forbes, Marketwatch, Yahoo Finance, 
                    and numerous other media outlets across the globe in addition to extensive work with investment banks and financial institutions.
                    Visit the media links on our homepage to find out more about how alternative financial data engineering
                    can help boost your institution's productivity. Click the social icons on the cards for more information on our leadership
                    if a contractual relationship or press release is of interest.
                    </h2>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <div className='team_div'>
                    <img src={images3} alt="" />
                    <div className="team_des">
                    <div className="team_name_desig">
                      <a href="#" className="Tname_a">
                        <h3>Sean Adler</h3>
                        <p>Founder and CEO</p>
                      </a>
                    </div>
                    <ul className="team_social_links">
                      <li>
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/seanmadler/"
                          className="Tsocial_link"
                        >
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>

        <section className='data_assets_sec'>
          <div className='container box-container '>
            <div className='data_assets_div'>
              <h2 className='main_assets_hd'>
              The Blue Swan: A Different Kind of Animal for the AI Renaissance
              </h2>
              <div className='row'>
                <div className='col-md-6 col-sm-12 col-xs-12'>
                  <div className='row'>
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <a href='https://bit.ly/gzi_contentpage' target='_blank'>
                        <div className='assets_img_box small_img_box'>
                          <img src={images1} />
                          <div className='assets_text_box'>
                            <p>
                              38% of hedge fund managers worldwide believed there would be widespread adoption for alternative data in the next three to five years.
                              Sign up for a free trial to position your firm for success in the AI arms race.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>

                    {/* mobile view*/}
                    <div className='col-md-6 col-sm-12 col-xs-12 view_in_mob'>
                      <a href='https://bit.ly/gzi_community' target='_blank'>
                        <div className='assets_img_box large_img_box'>
                          <img src={images1} />
                          <div className='assets_text_box'>
                            <p>
                              38% of hedge fund managers worldwide believed there would be widespread adoption for alternative data in the next three to five years.
                              Sign up for a free trial to position your firm for success in the AI arms race.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* //mobile view*/}

                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <a href='https://bit.ly/gzi_contentpage' target='_blank'>
                        <div className='assets_img_box small_img_box'>
                          <img src={images12} />
                          <div className='assets_text_box'>
                            <p>
                              Returns for AI/ML, systematic and normal hedge funds were 7.6%, 5.22%, 6.03% over the last five years.
                              Our platform is trained on the same data used to outperform leading robo advisors and quant funds prior to GZI being listed for an 8-figure exit.
                              Visit our content page to experience the value for yourself.
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 col-xs-12 hide_in_mob'>
                  <a href='https://bit.ly/gzi_community' target='_blank'>
                    <div className='assets_img_box large_img_box'>
                      <img src={images2} />
                      <div className='assets_text_box'>
                        <p>
                          Utilize backtested data verified through live trading results to structure investment portfolios and financial products like ETFs.
                          Those seeking alpha can weigh their beta on our platform to enjoy smoother portfolio results.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='only_text_section'>
          <div className='container box-container'>
            <div className='text_only_box'>
              <p>
                Nothing contained on this site constitutes tax, legal, insurance
                or investment advice. Neither the information, nor any opinion,
                contained on this site constitutes a solicitation or offer by
                GZI or its affiliates to buy or sell any securities, futures,
                options or other financial instruments, nor shall any such
                security be offered or sold to any person in any jurisdiction in
                which such offer, solicitation, purchase, or sale would be
                unlawful under the securities laws of such jurisdiction.
              </p>
              <p>
                The risk of loss in online trading of stocks, options, futures,
                currencies, foreign equities, and fixed income can be
                substantial.
              </p>
              <p>
                IN NO EVENT SHALL ANY OF THE GZI PARTIES BE LIABLE FOR ANY
                INVESTMENT GAIN/LOSS YOU INCUR DURING YOUR INVESTMENT IN THE
                STOCK MARKET.
              </p>
            </div>
          </div>
        </section>
        <footer>
          <section className='footer-sec'>
            <div className='container'>
              <div className='row pt-5'>
                <div className='col-md-3 col-sm-3 col-xs-12'>
                  <div className='footer_col footer-logo'>
                    <img src={logo} />
                    <p>
                      GZI empowers investors with alternative financial data
                      engineering.
                    </p>
                  </div>
                </div>
                <div className='col-md-9 col-sm-9 col-xs-12'>
                  <div id='accordion' className='row'>
                    <div className='footer_col card col-md-4 col-sm-4 col-xs-12'>
                      <div className='card-header' id='headingOne'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link'
                            data-toggle='collapse'
                            data-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            Contact
                          </button>
                        </h2>
                      </div>

                      <div
                        id='collapseOne'
                        className='collapse'
                        aria-labelledby='headingOne'
                        data-parent='#accordion'
                      >
                        <div className='card-body'>
                          <div className='block_box'>
                            <h3>Write to Us</h3>
                            <ul className='footer_links flex_ul_li'>
                              <li>
                                <i className='fa fa-envelope'></i>
                                <a href='mailto:sadler@gzi.app'>
                                  sean.adler@gzi.finance
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className='block_box'>
                            <h3>Give us a Call</h3>
                            <ul className='footer_links flex_ul_li'>
                              <li>
                                <i className='fa fa-phone-alt'></i>
                                <a
                                  className='no_decor'
                                  href='tel:+1(303)5193561'
                                >
                                  +1-(303)-519-3561
                                </a>
                              </li>
                              <li>
                                <i className='fa fa-phone-alt'></i>
                                <a
                                  className='no_decor'
                                  href='https://bit.ly/gzicalendly'
                                >
                                  Schedule A Call
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='footer_col card col-md-4 col-sm-4 col-xs-12'>
                      <div className='card-header' id='headingTwo'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            data-toggle='collapse'
                            data-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            Sitemap
                          </button>
                        </h2>
                      </div>
                      <div
                        id='collapseTwo'
                        className='collapse'
                        aria-labelledby='headingTwo'
                        data-parent='#accordion'
                      >
                        <div className='card-body'>
                          <div className='block_box'>
                            <ul className='footer_links block_ul_li'>
                              <li>
                                <Link to='/home'>Home</Link>
                              </li>
                              <li>
                                <Link to='/about-us'>About</Link>
                              </li>
                              <li>
                                <Link to='/content'>Content</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='footer_col card col-md-4 col-sm-4 col-xs-12'>
                      <div className='card-header' id='headingThree'>
                        <h2 className='mb-0'>
                          <button
                            className='btn btn-link collapsed'
                            data-toggle='collapse'
                            data-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            Account
                          </button>
                        </h2>
                      </div>
                      <div
                        id='collapseThree'
                        className='collapse'
                        aria-labelledby='headingThree'
                        data-parent='#accordion'
                      >
                        <div className='card-body'>
                          <div className='block_box'>
                            <ul className='footer_links block_ul_li'>
                              <li>
                                <Link to='/auth/signin'>Log In</Link>
                              </li>
                              <li>
                                <Link to='/auth/signup'>Open An Account</Link>
                              </li>
                              <li>
                                <Link to='/auth/signup'>Free Trail</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='copyright_box'>
                <div className='row'>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <p>© 2024 GZI Hub Inc. All Rights Reserved.</p>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <ul>
                      <li>
                        <a href='#'>
                          <i className='fa-brands fa-facebook'></i>
                        </a>
                      </li>
                      <li>
                        <a target='_blank' href='https://twitter.com/swan_gzi'>
                          <i className='fa-brands fa-twitter'></i>
                        </a>
                      </li>
                      <li>
                        <a target='_blank' href='https://bit.ly/gzi_community'>
                          <i className='fa-brands fa-linkedin'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <p className='last_p'>
                      <Link to='/terms-and-condition'>Terms & Conditions</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </footer>
      </section>
    </>
  );
};

export default Section;
