import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

function StatsSection(props) {
  const items = [
    {
      title: "GZI",
      stat: "58%",
    },
    {
      title: "D1 Capital Partners",
      stat: "86.8%",
    },
    {
      title: "Hudson Bay Capital Management",
      stat: "69.6%",
    },
    {
      title: "Saba Capital Mgmt.",
      stat: "67.6%",
    },
    {
      title: "Weiss Multi-Strategy Advisers",
      stat: "42.1%",
    },
    {
      title: "UBS O'Connor",
      stat: "40.7%",
    },
    {
      title: "Garda Capital Partners",
      stat: "39.6%",
    },
    {
      title: "Exodus Point Capital",
      stat: "39.5%",
    },
    {
      title: "Brevan Howard Asset Mgmt.",
      stat: "37.6%",
    },
    {
      title: "Ellington Mgmt. Group",
      stat: "36.2%",
    },
    {
      title: "Snow Lake Capital",
      stat: "35%",
    },
    {
      title: "Pershing Square",
      stat: "28.7%",
    },
    {
      title: "Nassar Capital Mgmt.",
      stat: "26%",
    },
    {
      title: "Point 72 Asset Mgmt.",
      stat: "22.9%",
    },
    {
      title: "Samlyn Capital",
      stat: "21.4%",
    },
    {
      title: "Blackrock",
      stat: "21.3%",
    },
    {
      title: "D.E. Shaw Group",
      stat: "19.5%",
    },
    {
      title: "TIG Advisors",
      stat: "19.1%",
    },
    {
      title: "Capstone Investment Advisors",
      stat: "17.8%",
    },
    {
      title: "Light Street Capital",
      stat: "16.8%",
    },
    {
      title: "Balyasny Asset Mgmt.",
      stat: "16.7%",
    },
    {
      title: "Capula Investment Mgmt.",
      stat: "16.2%",
    },
    {
      title: "LibreMax Capital",
      stat: "14.3%",
    },
    {
      title: "P. Schoenfeld Asset Mgmt.",
      stat: "14.3%",
    },
    {
      title: "GoldenTree Asset Mgmt.",
      stat: "10.7%",
    },
    {
      title: "Elliot Mgmt.",
      stat: "11.2%",
    },
    {
      title: "Tudor Investment",
      stat: "11%",
    },
    {
      title: "Farallon Capital Mgmt.",
      stat: "8.7%",
    },
    {
      title: "PDT Partners",
      stat: "8.3%",
    },
    {
      title: "Steadfast Capital Mgmt.",
      stat: "8.0%",
    },
    {
      title: "Quest Partners",
      stat: "7.4%",
    },
    {
      title: "Citadel",
      stat: "6.5%",
    },
    {
      title: "EJF Capital",
      stat: "5.0%",
    },
    {
      title: "Lone Pine Capital",
      stat: "4.6%",
    },
    {
      title: "Napier Global Capital",
      stat: "4.5",
    },
    {
      title: "Greenlight Capital",
      stat: "4.0%",
    },
    {
      title: "Grahm Capital",
      stat: "3.4%",
    },
    {
      title: "Aspect Capital",
      stat: "3.1%",
    },
    {
      title: "Davidson Kempner Capital Mgmt.",
      stat: "3.1%",
    },
    {
      title: "Renaissance Technologies",
      stat: "2.9%",
    },
    {
      title: "Marshall Wace",
      stat: "2.6%",
    },
    {
      title: "Atlantic Investment Mgmt.",
      stat: "2.4%",
    },
    {
      title: "HG Vora Cital Mgmt.",
      stat: "1.9%",
    },
    {
      title: "Cheyne Capital",
      stat: "1.6%",
    },
    {
      title: "Baupost Group",
      stat: "0.7%",
    },
    {
      title: "Man Group",
      stat: "0.5%",
    },
    {
      title: "PIMCO",
      stat: "0.4%",
    },
    {
      title: "Tilden Park Capital Mgmt.",
      stat: "0.3%",
    },
    {
      title: "Pharo Mgmt.",
      stat: "0.0%",
    },
    {
      title: "Light Sky Macro",
      stat: "0.0%",
    },
    {
      title: "Birch Grove Capital",
      stat: "-0.2%",
    },
    {
      title: "Sculptor Capital Mgmt.",
      stat: "-1.6%",
    },
    {
      title: "Greywolf Capital Mgmt.",
      stat: "-2.9%",
    },
    {
      title: "Paloma Partners",
      stat: "-4.3%",
    },
    {
      title: "Wellington Alternative Investments",
      stat: "-4.5%",
    },
    {
      title: "Gramercy Funds Mgmt.",
      stat: "-4.7%",
    },
    {
      title: "Angelo Gordon",
      stat: "-5.2%",
    },
    {
      title: "Taconic Capital Advisors",
      stat: "-6.1%",
    },
    {
      title: "Senator Investment Group",
      stat: "-9.5%",
    },
    {
      title: "Two Sigma",
      stat: "-9.5%",
    },
    {
      title: "Anchorage Capital Group",
      stat: "-10%",
    },
    {
      title: "Nephila Capital",
      stat: "-10.7%",
    },
    {
      title: "York Capital Mgmt.",
      stat: "-10.8%",
    },
    {
      title: "Appolosa Mgmt.",
      stat: "-10.8%",
    },
    {
      title: "Element Capital Mgmt.",
      stat: "-11.1%",
    },
    {
      title: "Windham Capital Mgmt.",
      stat: "-11.4%",
    },
    {
      title: "Varde Partners",
      stat: "-12.3%",
    },
    {
      title: "Empyrean Capital Partners",
      stat: "-14.4%",
    },
    {
      title: "Magnetar Capital",
      stat: "-15.7%",
    },
    {
      title: "Kepos Capital",
      stat: "-16.7%",
    },
    {
      title: "Hildene Capital Mgmt.",
      stat: "-19.9%",
    },
    {
      title: "Kingdon Capital Mgmt.",
      stat: "-24.2%",
    },
    {
      title: "Carlson Capital Mgmt.",
      stat: "-24.4%",
    },
    {
      title: "Autonomy Capital",
      stat: "-25.0%",
    },
    {
      title: "Bridgewater Associates",
      stat: "-25.1%",
    },
    {
      title: "King Street Capital Mgmt.",
      stat: "-25.1%",
    },
    {
      title: "MKP Capital Mgmt.",
      stat: "-25.4%",
    },
    {
      title: "Contrarian Capital",
      stat: "-25.9%",
    },
    {
      title: "Graticule Asset Mgmt.",
      stat: "-42.2%",
    },
    {
      title: "Winton Group",
      stat: "-46.6%",
    },
    {
      title: "AQR Capital Mgmt.",
      stat: "-47.2%",
    },
    {
      title: "J.P. Morgan Asset Mgmt.",
      stat: "-54.1%",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container={true} justify="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} sm={3} key={index}>
              <Box textAlign="center">
                <Typography variant="overline">{item.title}</Typography>
                <Typography variant="h4">{item.stat}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default StatsSection;
